import {
  PartialCourse,
  PartialPath,
  CollectionTypeResponse,
  EnumResponse,
  UserDisplayFields,
} from "./types";
import { get, sumBy } from "lodash";

export function resolveCmsMediaUrl(path?: string | null) {
  if (!path) return "";

  return /^\/uploads/.test(path) // is image from CMS
    ? `${process.env.VUE_APP_CMS_URL}${path}`
    : path;
}

export function getCourseThumbnailUrl(
  courseOrPath?: PartialCourse | PartialPath | null,
  format?: "thumbnail" | "small" | "medium" | "large" | "original"
) {
  if (!courseOrPath) return "";

  if (format === "original") {
    return resolveCmsMediaUrl(
      get(courseOrPath, "attributes.thumbnail.data.attributes.url")
    );
  }

  return resolveCmsMediaUrl(
    get(
      courseOrPath,
      `attributes.thumbnail.data.attributes.formats.${format ?? "small"}.url`
    ) || get(courseOrPath, "attributes.thumbnail.data.attributes.url")
  );
}

export function normalizeEnumEntityResponse(
  data?: CollectionTypeResponse<EnumResponse> | EnumResponse[] | null
) {
  if (!data) {
    return [];
  }

  return ("data" in data ? data.data : data).map(
    ({ id, attributes: { info, ...attributes } }) => ({
      id,
      ...attributes,
      ...info,
    })
  );
}

export function injectCourseMetaFields(
  courseOrPath: PartialCourse | PartialPath | null | undefined,
  type: "course" | "path" = "course"
) {
  if (!courseOrPath) return;

  const slug = courseOrPath.attributes?.slug || courseOrPath.id;

  courseOrPath._url = `/${type === "path" ? "seria" : "kurz"}/${slug}`;
  courseOrPath._model = type;
}

export function getUserDisplayName(
  data: Record<string, unknown>,
  pickFields: { [Key in keyof UserDisplayFields]?: boolean } = {}
): string {
  const { firstName, lastName, username, email } = (get(
    data,
    "attributes.user.data.attributes"
  ) ??
    get(data, "attributes.groupMember.data.attributes.user.data.attributes") ??
    get(data, "attributes") ??
    get(data, "user") ??
    data ??
    {}) as Partial<UserDisplayFields>;

  if (pickFields.email) {
    return email || username || "";
  }

  if (firstName || lastName) {
    return `${firstName} ${lastName}`.trim();
  }

  return username || email || "";
}

export function getPoints(data: { response: unknown; course: unknown }): {
  correctPoints: number;
  maxPoints: number;
} {
  const correctPoints = get(data.response, "attributes.correctPoints", 0);

  const course = get(data.response, "attributes.course", data.course);
  const questions = get(
    course,
    "data.attributes.quiz.data.attributes.questions",
    []
  );

  const maxPoints = sumBy(questions, "points");

  return { correctPoints, maxPoints };
}

export function getQuizFormUrls(data: { course: unknown; user: unknown }): {
  publicUrl: URL | null;
  iframeSrc: URL | null;
} {
  const form = get(data.course, "attributes.quiz.data.attributes.form");
  const publicId = get(form, "publicId");

  if (!publicId) {
    return { publicUrl: null, iframeSrc: null };
  }

  const baseUrl = `https://docs.google.com/forms/d/e/${publicId}/viewform`;

  // structuredClone() would be nicer, but it is node.js 17v+
  const publicUrl = new URL(baseUrl);
  const iframeSrc = new URL(baseUrl);

  const emailPrefillId = get(form, "emailPrefillId");
  const email = get(data.user, "email");

  iframeSrc.searchParams.set("embedded", "true");

  if (emailPrefillId && email) {
    iframeSrc.searchParams.set(emailPrefillId, email);
  }

  return { publicUrl, iframeSrc };
}
