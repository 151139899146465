<template>
  <div>
    <AutoPopup />
    <div class="a-profile-subpage-title -favourites">
      <div class="heading">
        <h1>{{ $t("Moje kurzy") }}</h1>
        <p>
          {{
            isAdmin
              ? $t("Váš výber zo všetkých kurzov a sérií")
              : $t("Tvoj výber zo všetkých kurzov a sérií")
          }}
        </p>
      </div>
      <div class="z-icon-wrapper -large">
        <svg-heart-icon class="w-color-secondary -heart" />
      </div>
    </div>
    <b-tabs>
      <b-tab :title="$t('Zoznam kurzov a sérií')" active>
        <Loadable
          class="mt-sm-5 mt-3"
          :items="starredCourses"
          :msgEmpty="emptyMessage"
        />
        <b-row class="mt-sm-5 mt-3">
          <b-col
            v-for="(course, i) in starredCourses"
            :key="i"
            md="6"
            class="d-flex col-xxl-4"
          >
            <CourseCard v-if="course._model === 'course'" :course="course" />
            <PathCard v-else :path="course" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import axios from "/utils/axios";
import { mapGetters } from "vuex";
import { injectCourseMetaFields } from "/utils/helpers";
import AutoPopup from "/components/AutoPopup.vue";
import { isEmpty } from "lodash";

import * as Sentry from "@sentry/vue";

const api = {
  favoriteCourses: (user) => {
    return axios.getAuth("courses", {
      params: {
        populate: ["thumbnail", "badges", "subtitleLanguages.flag"],
        filters: {
          id: {
            $in: !isEmpty(user.favoriteCourses)
              ? user.favoriteCourses.map(({ id }) => id)
              : [-1],
          },
        },
      },
    });
  },
  favoritePaths: (user) => {
    return axios.getAuth("paths", {
      params: {
        populate: ["thumbnail", "subtitleLanguages.flag"],
        filters: {
          id: {
            $in: !isEmpty(user.favoritePaths)
              ? user.favoritePaths.map(({ id }) => id)
              : [-1],
          },
        },
      },
    });
  },
};

export default {
  layout: "profile",

  components: {
    AutoPopup,
    PathCard: () => import("/components//Path/PathCard.vue"),
    "svg-heart-icon": () => import("/assets/icons/heart.svg?inline"),
    CourseCard: () => import("/components/CourseCard.vue"),
    Loadable: () => import("/components/Loadable.vue"),
  },

  data() {
    return {
      starredCourses: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
    emptyMessage() {
      return this.isAdmin
        ? "Nemáte žiadne obľúbené kurzy alebo série."
        : "Nemáš žiadne obľúbené kurzy alebo série.";
    },
  },

  async created() {
    try {
      const favoriteCourses = await api.favoriteCourses(this.user);
      const favoritePaths = await api.favoritePaths(this.user);

      this.favoriteCourses = favoriteCourses.data || [];
      this.favoritePaths = favoritePaths.data || [];

      this.favoriteCourses.forEach((course) => {
        injectCourseMetaFields(course, "course");
      });

      this.favoritePaths.forEach((path) => {
        injectCourseMetaFields(path, "path");
      });

      this.starredCourses = this.favoriteCourses.concat(this.favoritePaths);
    } catch (error) {
      Sentry.captureException(error);
      this.starredCourses = { error: "Nepodarilo sa načítať Moje kurzy..." };
    }
  },
};
</script>
