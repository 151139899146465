<template>
  <Modal v-if="popup" v-model="isOpen" class="dialog" closeButtonHasBg>
    <img :src="popup.imgUrl" :alt="popup.imgAlt" />
    <div class="text-content">
      <h3 v-if="popup.title">{{ popup.title }}</h3>
      <p v-if="popup.description" v-html="$md.render(popup.description)"></p>
      <b-button v-if="popup.button" :href="popup.button.url">{{
        popup.button.label
      }}</b-button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import markdownIt from "markdown-it";
import axios from "/utils/axios";
import { resolveCmsMediaUrl } from "/utils/helpers";
import Modal from "/components/Modal.vue";

const PROFILE_ROUTE_NAMES = ["Profil", "Moje kurzy", "Diplomy", "Pomocník"];

const api = {
  popups() {
    return axios.get("popups", {
      params: { populate: ["picture", "button", "showOn", "showTo"] },
    });
  },
};
export default {
  components: {
    Modal,
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
  },

  data() {
    return {
      popup: undefined,
      isOpen: true,
    };
  },

  created() {
    this.$md = markdownIt({
      breaks: true,
      html: true,
    });

    api.popups().then(this.updateData);
  },

  methods: {
    updateData(data) {
      // Choose popup according to current conditions
      const popupHistory =
        JSON.parse(sessionStorage.getItem("popupHistory")) || {};
      const selectedPopup = data.data.find(({ attributes: popup, id }) => {
        const matchesUserType =
          (!this.isLoggedIn && popup.showTo.anonymous) ||
          (this.user.type === "student" && popup.showTo.student) ||
          (this.user.type === "teacher" && popup.showTo.teacher);
        if (!matchesUserType) return false;

        const matchesRouteName =
          (popup.showOn.homepage && this.$route.name === "Domov") ||
          (popup.showOn.profile &&
            PROFILE_ROUTE_NAMES.includes(this.$route.name)) ||
          (popup.showOn.forTeachers && this.$route.name === "Domov učitelia");
        if (!matchesRouteName) return false;

        const thisPopupHistory = popupHistory[id];
        if (!thisPopupHistory) return true;

        const occurenceDelayPassed =
          new Date() - new Date(thisPopupHistory.lastOccurence) >
          popup.nextOccurenceIn * 60 * 60 * 1000;
        if (!occurenceDelayPassed) return false;

        const maxOccurencesReached =
          thisPopupHistory.occurences >= popup.numberOfOccurences;
        if (maxOccurencesReached) return false;

        return true;
      });
      if (!selectedPopup) return;

      // Update popup history
      sessionStorage.setItem(
        "popupHistory",
        JSON.stringify({
          ...popupHistory,
          [selectedPopup.id]: {
            occurences: popupHistory[selectedPopup.id]
              ? popupHistory[selectedPopup.id].occurences + 1
              : 1,
            lastOccurence: new Date(),
          },
        })
      );

      setTimeout(
        () =>
          (this.popup = {
            imgUrl: resolveCmsMediaUrl(
              get(selectedPopup, "attributes.picture.data.attributes.url")
            ),
            imgAlt: get(
              selectedPopup,
              "attributes.picture.data.attributes.alternativeText"
            ),
            title: get(selectedPopup, "attributes.title"),
            description: get(selectedPopup, "attributes.description"),
            button: get(selectedPopup, "attributes.button") && {
              label: get(selectedPopup, "attributes.button.label"),
              url: get(selectedPopup, "attributes.button.url"),
            },
          }),
        selectedPopup.attributes.displayDelay * 1000 || 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_theme/_mixins.scss";

.dialog {
  width: min(95%, 45rem);
}
img {
  margin: -1rem 0 0 -1rem;
  width: calc(100% + 2rem);
  height: auto;
}
h3,
p {
  color: var(--a-color-dark);
}
.text-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem 0 0;
}
@include sm {
  .text-content {
    padding: 2rem 1rem 1rem;
  }
}
</style>
