<template>
  <dialog :class="showAtBottom ? 'bottom' : 'center'" ref="modal">
    <button
      ref="closeButton"
      :class="{ close: true, 'bg-white': closeButtonHasBg }"
      @click="$refs.modal.close()"
      aria-label="close"
    >
      <crossIcon />
    </button>
    <slot></slot>
  </dialog>
</template>

<script>
import crossIcon from "/assets/icons/cross.svg?inline";

export default {
  model: {
    prop: "isOpen",
    event: "isOpen",
  },

  props: {
    showAtBottom: {
      required: false,
      type: Boolean,
      default: false,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    closeButtonHasBg: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  components: {
    crossIcon,
  },

  methods: {
    openOrClose(open) {
      const dialog = this.$refs.modal;
      const closeButton = this.$refs.closeButton;

      if (open) {
        dialog.showModal();
        closeButton.focus();
        document.documentElement.classList.add("modal-open");
        return;
      }

      this.$refs.modal.close();
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.openOrClose(this.isOpen);
    });

    const dialog = this.$refs.modal;

    dialog.addEventListener("click", (event) => {
      let rect = event.target.getBoundingClientRect();

      if (
        rect.left > event.clientX ||
        rect.right < event.clientX ||
        rect.top > event.clientY ||
        rect.bottom < event.clientY
      ) {
        this.$emit("isOpen", false);
      }
    });

    dialog.addEventListener("close", () => {
      this.$emit("isOpen", false);
      document.documentElement.classList.remove("modal-open");
    });
  },

  watch: {
    isOpen(newValue) {
      this.openOrClose(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_theme/_mixins.scss";

dialog {
  max-width: 100%;

  border: 0;
  border-radius: 0.625rem 0.625rem 0rem 0rem;

  --bottom-border-width: 0.283125rem;
}

dialog::backdrop {
  background-color: rgba(#2b2e4a, 0.6);
}

.center {
  width: min(95%, 40rem);

  border-bottom: var(--bottom-border-width) solid;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
    100% 0/0 0 var(--bottom-border-width) 0 stretch;
}

.bottom {
  margin-bottom: 0;
  width: min(100%, 40rem);
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  --button-dimension: 2.25rem;
  width: var(--button-dimension);
  height: var(--button-dimension);
  border-radius: var(--button-dimension);

  .bg-white {
    background-color: white;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
}

@include sm {
  .bottom {
    margin-bottom: auto;

    border-bottom: var(--bottom-border-width) solid;
    border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 0 0
      100% 0/0 0 var(--bottom-border-width) 0 stretch;
  }
}
</style>
